:root {
  --headerFont: 'Roboto', sans-serif;
  --bodyFont: 'Roboto', Helvetica;
  --red: rgba(253, 94, 83, 1);
  --orange: rgba(252, 156, 84, 1);
  --orange-fifty: rgba(252, 156, 84, 0.5);
  --gold: rgba(255, 227, 115, 1);
  --gold-fifty: rgba(255, 227, 115, 0.5);
  --white: rgba(255, 255, 255, 1);
  --black: rgba(0, 0, 0, 1);
  --black-zero: rgba(0, 0, 0, 0);
  --black-twenty: rgba(0, 0, 0, 0.2);
  --black-eightyfive: rgba(0, 0, 0, 0.85);
  --red: rgba(253, 94, 83, 1);
  --light-grey: rgba(20, 20, 20, 1);
  --grey: rgba(200, 200, 200, 1);
  --grey-zero: rgba(200, 200, 200, 0);
  --grey-fifty: rgba(200, 200, 200, 0.5);
  --transition-delay: 0.5s;
  --transition-delay-step: 0.15s;
  --cubic: cubic-bezier(0.19, 1, 0.22, 1);
}

#ryans-portfolio {
  position: relative;
}

html {
  font-size: 10px;
  scrollbar-color: var(--orange) var(--black);
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #201c29, #201c29 1px, #100e17 0, #100e17);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, var(--red), var(--orange), var(--gold));
  border-radius: 5px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
  cursor: grab;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, var(--orange), var(--orange));
}

::selection {
  background-color: rgba(0, 0, 0, 0);
  color: var(--orange);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black);
  color: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
