.menu-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 8;
}

.hamburger {
  position: absolute;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  width: 50px;
  height: 4px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 5px var(--black-twenty);
  transition: 0.8s, background-color 0.3s ease-in-out 0.7s;
}

.hamburger:before,
.hamburger:after {
  content: '';
  position: absolute;
  background-color: var(--white);
  width: 50px;
  height: 4px;
  box-shadow: 0px 2px 5px var(--black-twenty);
  transition: 0.8s, background-color 0.3s ease-in-out 0.7s,
    transform 1s cubic-bezier(0.51, -0.91, 0.24, 1.16);
  z-index: 8;
}

.hamburger:before {
  top: -13px;
  right: 0px;
}

.hamburger:after {
  top: 13px;
  right: 0px;
}

.hamburger-text {
  position: absolute;
  justify-content: space-between;
  font-family: Roboto, sans-serif;
  font-size: 1.6rem;
  font-weight: 800;
  color: var(--white);
  top: 60px;
  right: 15.5px;
  transition: 0.8s;
  text-align: center;
  width: 50px;
  letter-spacing: 0.1em;
}

// .menu-icon:hover .hamburger-text {
//   color: var(--orange);
// }

.menu-icon.menu-open .hamburger,
.menu-icon.menu-open .hamburger:before,
.menu-icon.menu-open .hamburger:after {
  background-color: var(--orange);
  transition: 0.8s, background-color 0s ease-in-out 0s,
    transform 1s cubic-bezier(0.51, -0.91, 0.24, 1.16);
}

.menu-icon.menu-open .hamburger {
  background-color: var(--black-zero);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0);
  transition: 0.8s, background-color 0s ease-in-out 0s;
}

.menu-icon.menu-open .hamburger:before {
  transform: rotate(405deg);
  top: 0;
  transition: 0.8s, background-color 0s ease-in-out 0s,
    transform 1s cubic-bezier(0.51, -0.91, 0.24, 1.16);
}

.menu-icon.menu-open .hamburger:after {
  transform: rotate(-405deg);
  top: 0;
  transition: 0.8s, background-color 0s ease-in-out 0s,
    transform 1s cubic-bezier(0.51, -0.91, 0.24, 1.16);
}
