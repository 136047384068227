#projects-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90vw;
  margin: 80px auto 0px auto;
  justify-content: center;
  padding-bottom: 80px;
}

.projectContainer {
  width: 100%;
  margin: 15px;
  // box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 0);
  // filter: drop-shadow(0px 12px 12px rgba(255, 255, 255, 0));
  transition: box-shadow 1s ease-in-out, transform 1s ease-in-out;
  transform: scale(1);
  &:hover {
    // box-shadow: 0px 0px 12px 6px rgba(255, 255, 255, 0.5);
    // filter: drop-shadow(0px 0px 12px rgba(255, 255, 255, 0.5));
    transform: scale(1.03);
  }
}

.projectContent {
  background-color: rgba(20, 20, 20, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
}

@for $i from 1 through 14 {
  div.projectContainer:nth-child(#{$i}n) div.windowHeader {
    transition-delay: #{$i * 0.1}s;
  }
}

@for $i from 1 through 14 {
  div.projectContainer:nth-child(#{$i}n) .preview {
    transition-delay: #{$i * 0.1 + 1}s;
  }
}

.projectContent:hover {
  opacity: 1;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}

.projectContent:hover li {
  opacity: 1;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}

@for $i from 0 through 20 {
  .projectContent:hover li:nth-child(#{$i}) {
    transition-delay: 0.35s + 0.05s * $i;
  }
}

.projectSkills {
  display: inline-flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

.projectSkills li {
  height: auto;
  padding: 5px;
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: 800;
  color: var(--black);
  background: linear-gradient(to right, var(--gold), var(--orange));
  margin: 5px;
  opacity: 0;
}

.viewProject {
  color: var(--white);
  margin: 20px 10px 10px 10px;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease-in-out;
  line-height: 1;
  &:hover {
    transform: scale(1.05);
    text-decoration: none;
  }
}

// ascent using experience image
// .ascent {
//   background-image: url('../../assets/images/experience/ascent-wide.jpg');
// }

.loan-sales {
  background-image: url('../../assets/images/projects/loan-sales-app-tall.png');
}
.college-los {
  background-image: url('../../assets/images/projects/college-los.png');
}
.student-success {
  background-image: url('../../assets/images/projects/ss-ios.png');
}
.college-partner {
  background-image: url('../../assets/images/projects/college-partner.png');
}
.partner {
  background-image: url('../../assets/images/projects/partner.png');
}
.bootcamp-finder {
  background-image: url('../../assets/images/projects/bootcamp-finder.png');
}
.pdm {
  background-image: url('../../assets/images/projects/pdm-dark-mode.png');
}

.pbhomes {
  background-image: url('../../assets/images/projects/pbhomes-wide.png');
}

.playgrounds {
  background-image: url('../../assets/images/projects/playgrounds.png');
}

.travel {
  background-image: url('../../assets/images/projects/travel.png');
}

.samport {
  background-image: url('../../assets/images/projects/samport.png');
}

.bishop {
  background-image: url('../../assets/images/projects/bishop.png');
}

.chiggybank {
  background-image: url('../../assets/images/projects/chiggybank.png');
}

.burger {
  background-image: url('../../assets/images/projects/burger.png');
}

.audioDB {
  background-image: url('../../assets/images/projects/audioDB.png');
}

.gatesVacation {
  background-image: url('../../assets/images/projects/gates.png');
}

.jamming {
  background-image: url('../../assets/images/projects/jamming.png');
}

.ravenous {
  background-image: url('../../assets/images/projects/ravenous.png');
}

.musicon {
  background-image: url('../../assets/images/projects/musicon.png');
}

.teaCozy {
  background-image: url('../../assets/images/projects/TeaCozy.png');
}

.portfolio {
  background-image: url('../../assets/images/projects/portfolio.png');
}

.robot {
  background-image: url('../../assets/images/projects/robot.png');
}

@media (min-width: 600px) {
  #projects-content {
    width: 85vw;
    margin: 100px auto 0px auto;
  }

  .projectContainer {
    width: 90%;
    margin: 15px;
  }
}

@media (min-width: 769px) {
  #projects-content {
    width: 95vw;
  }

  .projectContainer {
    max-width: 42.5%;
  }
}

@media (min-width: 1025px) {
  #projects-content {
    margin: 120px auto 0px auto;
  }

  .projectContainer {
    max-width: 30%;
  }

  .viewProject {
    font-size: 2.2rem;
  }
}

@media (min-width: 1600px) {
  .projectContainer {
    max-width: 22%;
  }
}
