#skills-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;
  margin: auto;
  padding-bottom: 80px;
}

.skills-intro {
  position: relative;
  color: var(--white);
  margin: 30px 80px 25px 25px;
  font-size: 1.6rem;
  text-align: center;
  &:after {
    content: 'SKILLS';
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    // right: 0;
    margin: auto;
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    color: rgba(255, 255, 255, 0.1);
  }
}

.chart-wrapper {
  width: 80%;
  height: 30vh;
  margin: 20px auto 100px auto;
  display: flex;
}

.chart-levels {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding-right: 10px;
  max-width: 55px;
  margin-left: -25px;
}

.chart-levels li {
  color: var(--white);
  position: relative;
  width: 100%;
  &:after {
    content: '—';
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    opacity: 0.95;
  }
}

.bar-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-grow: 1;
  text-align: center;
}

.chart-skills li {
  width: 12%;
  height: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: height 0.65s cubic-bezier(0.51, 0.91, 0.24, 1.16);
  color: var(--white);
  font-size: 1.2rem;
}

.chart-skill {
  position: relative;
}

.chart-skill-text {
  position: absolute;
  width: 100%;
}

.chart-skills li:nth-child(odd) {
  background: linear-gradient(to top, var(--gold), var(--orange));
}

.chart-skills li:nth-child(even) {
  background: linear-gradient(to bottom, var(--gold), var(--orange));
}

$transition-delay: 0.8s;
$transition-delay-step: 0.15s;

@for $i from 1 through 9 {
  .chart-skills li:nth-child(#{$i}n) {
    transition-delay: #{$transition-delay + $transition-delay-step * $i - 1};
  }
}

// only show in larger screen

/* .chart-skills li::before { 
      content: attr(data-level);
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      font-size: 0.85rem;
      color: var(--white;
  } */

.chart-skills span {
  bottom: 10px;
  left: 5px;
  transform: translateY(40px) rotate(45deg);
}

.otherSkills {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.otherSkills ul {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  max-width: 1200px;
  margin-inline: auto;
}

.otherSkills li {
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  color: var(--black);
  background: linear-gradient(to right, var(--gold), var(--orange));
}

.otherSkills li.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
}

.load-more {
  width: fit-content;
  margin-inline: auto;
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 10px;
  border-width: 0px;
  // color: var(--black);
  color: var(--white);
  cursor: pointer;
  outline: 2px solid var(--orange);
  background-color: transparent;
  // color: var(--white);
  // background-color: (var(--orange));
  // transition: all 0.3s ease-in-out;
  // opacity: 1;
}

.load-more:hover {
  color: var(--black);
  background: linear-gradient(to right, var(--gold), var(--orange));
  outline: none;
  // opacity: 0.7;
}

@media (min-width: 370px) {
  .chart-skills li {
    font-size: 1.4rem;
  }
}

@media (min-width: 487px) {
  .skills-intro {
    margin: 40px auto 25px auto;
  }
}

@media (min-width: 500px) {
  .chart-skills span {
    bottom: 5px;
  }
}

@media (min-width: 600px) {
  #skills-content {
    width: 85%;
  }

  .chart-skills span {
    bottom: 0px;
    left: 0;
  }

  .chart-skills li {
    font-size: 1.6rem;
  }

  .otherSkills li,
  .load-more {
    font-size: 1.8rem;
  }
}

@media (min-width: 769px) {
  .skills-intro {
    margin: 120px auto 25px auto;
  }

  .chart-wrapper {
    width: 90%;
    margin: 25px auto 120px auto;
  }

  .chart-skills span {
    bottom: -10px;
  }

  .chart-skills li {
    font-size: 1.8rem;
  }

  .chart-levels li {
    padding-right: 10px;
  }
}

@media (min-width: 1025px) {
  .skills-intro {
    margin: 140px auto 25px auto;
  }

  .chart-levels {
    max-width: 85px;
    li {
      font-size: 1.6rem;
    }
  }

  .chart-skills span {
    bottom: -20px;
  }
  .chart-skills li {
    font-size: 2rem;
  }
  .otherSkills li,
  .load-more {
    font-size: 2rem;
  }
}

@media (min-width: 1350px) {
  .skills-intro {
    margin: 180px auto 25px auto;
  }

  .chart-wrapper {
    width: 90%;
    margin: 25px auto 120px auto;
  }
}
