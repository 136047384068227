// $shadow-one: 0 4px 12px 0 rgba(255, 255, 255, 0);
$cubic: cubic-bezier(0.19, 1, 0.22, 1);

.navigation-menu {
  opacity: 1;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  margin: auto;
  transition: opacity 2s linear;
}

.overlay {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  background-color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.95);
  overflow: hidden;
  transition: 1s; // used for height/width expansion transition
}

// Position the content inside the overlay
.overlay-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  padding: 10px;
}

// The navigation links inside the overlay
.overlay-content a {
  padding: 8px;
  text-decoration: none;
  font-size: 4.5em;
  line-height: 1em;
  font-weight: 800;
  color: var(--black);
  &:hover,
  &:focus {
    color: var(--orange);
  }
}

@media (min-width: 370px) {
  .overlay-content {
    padding: 0px 10px;
  }
  .overlay-content a {
    font-size: 5.5rem;
  }
}

// When the height of the screen is less than 450 pixels, change the font-size of the links so they don't overlap
@media screen and (max-height: 450px) {
  .overlay-content a {
    font-size: 20px;
  }
}

@media (min-width: 769px) {
  .desktop-nav {
    display: flex;
    li {
      display: flex;
      padding: 10px 20px;
      transition: transform 0.3s ease-in-out;
      a {
        display: flex;
        color: rgba(255, 255, 255, 0.8);
        text-decoration: none;
        font-size: 2rem;
        font-weight: 700;
        transition: font-size 0.3s ease-in-out;
      }
    }
  }

  .desktop-nav a.active {
    color: var(--white);
  }

  // Menu animation

  .menu-animation {
    overflow: hidden;
    position: relative;
    &:hover {
      transform: translateY(-2px);
    }

    // line animations apply to immediate first span
    &:before,
    &:after,
    > a:before,
    > a:after {
      content: '';
      position: absolute;
      display: block;
      transition: $cubic 1s;
    }

    // left and right lines
    &:before,
    > a:before {
      width: 2px;
      height: 0;
    }
    // left line
    &:before {
      top: 0;
      left: 0;
      background: linear-gradient(
        to bottom,
        var(--gold) 0%,
        var(--orange) 50%,
        var(--red) 100%
      );
    }
    // right line
    > a:before {
      bottom: 0;
      right: 0;
      background: linear-gradient(
        to top,
        var(--gold) 0%,
        var(--orange) 50%,
        var(--red) 100%
      );
    }
    // top and bottom lines
    &:after,
    > a:after {
      height: 2px;
      width: 0;
    }
    // top line
    &:after {
      top: 0;
      left: 0;
      background: linear-gradient(
        to right,
        var(--gold) 0%,
        var(--orange) 50%,
        var(--red) 100%
      );
    }
    // bottom line
    > a:after {
      bottom: 0;
      right: 0;
      background: linear-gradient(
        to left,
        var(--gold) 0%,
        var(--orange) 50%,
        var(--red) 100%
      );
    }
    // animate lines on hover
    &:hover {
      &:before,
      > a:before,
      &:after,
      > a:after {
        transition: $cubic 1s;
      }
      // left line
      &:before {
        animation: to-bottom $cubic 1s 0.05s forwards;
      }
      // right line
      > a:before {
        animation: to-top $cubic 1s 0.1s forwards;
      }
      // top line
      &:after {
        animation: to-right $cubic 1s 0.15s forwards;
      }
      // bottom line
      > a:after {
        animation: to-left $cubic 1s 0.2s forwards;
      }
    }
    &:hover:before,
    &:hover > a:before {
      height: 100%;
    }
    &:hover:after,
    &:hover > a:after {
      width: 100%;
    }
  }

  @keyframes to-bottom {
    to {
      transform: translateY(0%);
    }
  }
  @keyframes to-top {
    to {
      transform: translateY(-0%);
    }
  }
  @keyframes to-left {
    to {
      transform: translateX(-0%);
    }
  }
  @keyframes to-right {
    to {
      transform: translateX(0%);
    }
  }
}

@media (min-width: 1025px) {
  .desktop-nav li a {
    font-size: 3rem;
  }
}

@media (min-width: 1350px) {
  .desktop-nav li a {
    font-size: 4rem;
  }
}
