.home-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100%;
  text-align: center;
  // background-color: white;
  // transition: background-color 2s cubic-bezier(0.66, 0.01, 0.99, 0.2);
}

.home-text,
.slider-item {
  display: flex;
  position: relative;
  font-size: 3.25rem;
  transition: font-size 0.3s ease-in-out;
}

.home-text {
  color: var(--white);
}

.slider {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  height: calc(50vh + 100px);
  width: 100%;
}

.slider-item {
  position: absolute;
  list-style: none;
  margin: auto;
  text-align: center;
  line-height: 1.2;
  color: var(--orange);
  // background-color: var(--orange);

  // background-image: linear-gradient(
  //   to right,
  //   var(--gold) var(--orange),
  //   var(--red)
  // );

  // background-image: -webkit-linear-gradient(
  //   to right,
  //   var(--gold) var(--orange),
  //   var(--red)
  // );

  // background-size: 100%;
  // background-repeat: repeat;

  // // background-clip: text;
  // -webkit-background-clip: text;
  // -moz-background-clip: text;

  // -webkit-text-fill-color: transparent;
  // -moz-text-fill-color: transparent;
}

@media (min-width: 400px) {
  .home-text,
  .slider-item {
    font-size: 4rem;
  }
  .slider {
    height: calc(50vh + 120px);
  }
}

@media (min-width: 769px) {
  .home-text,
  .slider-item {
    font-size: 6rem;
  }
}

@media (max-width: 400px) and (min-height: 800px) {
  .slider {
    height: calc(50vh + 100px);
  }
}

@media (max-height: 550px) {
  .home-container {
    padding-top: 80px;
  }
  .home-text,
  .slider-item {
    font-size: 3rem;
  }
}
