.mr-robot {
  position: relative;
  //   background-color: #010165;
  margin: 0px;
  padding-bottom: 80px;
}

.robot-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background: #00ffff;
  text-align: center;
}

.robot-title {
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--black);
  padding-right: 46px;
}

.title-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 42px;
  margin-bottom: 21px;
  text-align: center;
}

.instructions-title {
  display: inline;
  font-size: 2.4rem;
  color: #00ffff;
  margin: 0px 10px;
}

.instructions-row {
  margin: 0 auto 80px auto;
  width: 100%;
  min-width: 320px;
  max-width: 600px;
}

.instructions-number {
  padding: 0px 15px 0px 15px;
  font-size: 48px;
  color: #00ffff;
}

.instructions-text {
  padding: 10px;
  padding-left: 0px;
  font-size: 18px;
  color: #fff;
}

.streak {
  margin: 20px;
  text-align: center;
  font-size: 2rem;
  line-height: 1;
  padding: 0;
}

.streak-value {
  color: #00ffff;
}

.door-row {
  text-align: center;
}

.door-frame {
  cursor: pointer;
  padding: 10px;
  filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.5));
  transition: all 0.3s ease-in-out;
  &:hover {
    filter: drop-shadow(0px 0px 14px rgba(255, 255, 255, 0.5));
  }
}

.start-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  box-sizing: border-box;
  max-width: 145px;
  padding: 20px;
  min-height: 104px;
  line-height: 1;
  background: #00ffff;
  font-size: 20px;
  text-align: center;
  color: var(--black);
  cursor: pointer;
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 8px 4px rgba(255, 255, 255, 0.5);
  }
}

@media (min-width: 769px) {
  .mr-robot {
    margin-top: 80px;
  }
  .robot-logo {
    font-size: 3.6rem;
  }
  .instructions-title {
    font-size: 3.6rem;
  }
  .instructions-text {
    font-size: 24px;
  }

  .streak {
    font-size: 3.2rem;
  }

  .door-frame {
    padding: 10px 30px;
  }

  .start-row {
    font-size: 3.2rem;
    max-width: 220px;
  }
}

@media (min-width: 1025px) {
  .mr-robot {
    margin-top: 100px;
  }
}

@media (min-width: 1350px) {
  .mr-robot {
    margin-top: 120px;
  }
}
