$grey: rgba(200, 200, 200, 1);
$grey-zero: rgba(200, 200, 200, 0);
$grey-fifty: rgba(200, 200, 200, 0.5);

#exp-content {
  width: 95%;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 80px;
}

.jobsContainer {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;
  justify-items: center;
  .preview {
    cursor: zoom-in;
  }
}

.jobContainer {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;
  justify-items: center;
  .preview {
    cursor: zoom-out;
  }
}

.job {
  min-width: 80%;
  max-width: 100%;
  margin: 80px auto 0px auto;
  flex-direction: column;
  transition: transform 0.8s;
  // box-shadow: 0px 0px 10px 5px $grey-zero;
  button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.job:hover {
  transform: scale(1.03);
  // box-shadow: 0px 0px 10px 5px $grey-fifty;
}

// window header tabs will load with slide up
@for $i from 1 through 5 {
  .job:nth-child(#{$i}n) div.windowHeader {
    transition-delay: #{$i * 0.1}s;
  }
}

// screen for the window header loads with fade-in
@for $i from 1 through 5 {
  .job:nth-child(#{$i}n) div.preview {
    transition-delay: #{$i * 0.1 + 1}s;
  }
}

.one,
.two,
.three,
.four,
.five {
  transition: 1s;
}

.jobContent {
  display: flex;
  flex-direction: column;
  cursor: default;
}

.title {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--white);
  margin-bottom: 10px;
  text-align: center;
}

.jobData,
.previous-role {
  flex-direction: row;
  margin: 0px 0px 20px 0px;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  color: var(--grey);
}

.jobData a {
  color: var(--white);
}

.dates {
  font-style: italic;
}

.employer {
  padding: 0px 15px;
  font-weight: 600;
}

.location {
  font-style: italic;
}

.jobSummary {
  font-size: 1.75rem;
  font-weight: 500;
  color: var(--white);
  font-style: italic;
  margin: 15px;
  text-align: center;
}

.tasks {
  max-width: 700px;
  list-style-position: outside;
}

.tasks li {
  list-style: inside;
  color: var(--grey);
  font-size: 1.5rem;
  line-height: 1.25;
  margin: 10px;
  // text-wrap: balance;
}

.point {
  background-image: url('../../assets/images/experience/1p21.jpg');
}

.ljagent {
  background-image: url('../../assets/images/experience/ljagent.jpg');
}

.nfunding {
  background-image: url('../../assets/images/experience/nationalfunding.jpg');
}

.heller {
  background-image: url('../../assets/images/experience/heller.jpg');
}

.ascent {
  background-image: url('../../assets/images/experience/ascent.png');
}

@media (min-width: 500px) {
  .title {
    font-size: 3.5rem;
  }

  .jobData,
  .previous-role {
    font-size: 1.8rem;
  }
  .jobSummary {
    font-size: 2.5rem;
  }
  .tasks li {
    font-size: 1.8rem;
  }
}

@media (min-width: 769px) {
  #exp-content {
    padding-top: 80px;
  }

  .jobContainer {
    .job {
      min-width: 60%;
      // max-width: 600px;
    }
  }

  .job {
    min-width: 40%;
    max-width: 90%;
    margin-top: 20px;
  }
}

@media (min-width: 1025px) {
  #exp-content {
    padding-top: 100px;
  }
  .job {
    min-width: 42.5%;
    max-width: 80%;
    margin: 30px;
  }
}

// @media (min-width: 1350px) {
//   #exp-content {
// padding-top: 120px;
//   }
// }
