//   colors
// $blue: rgba(4, 153, 255, 1);
// $purple: rgba(102, 51, 255, 1);
// $orange: rgba(252, 156, 84, 1);
// $gold: rgba(255, 227, 115, 1);
// $white: rgba(255, 255, 255, 1);
// $black: rgba(0, 0, 0, 1);
// $black-zero: rgba(0, 0, 0, 0);
// $black-twenty: rgba(0, 0, 0, 0.2);
// $red: rgba(253, 94, 83, 1);
// $grey: rgba(200, 200, 200, 1);
// $grey-zero: rgba(200, 200, 200, 0);
// $grey-fifty: rgba(200, 200, 200, 0.5);

//helper classes

.vh100 {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
}
.visible {
  opacity: 1;
}
.not-visible {
  opacity: 0;
}

//global classes

.windowHeader {
  background: #ddd;
  position: relative;
  border-radius: 3px 3px 0px 0px;
  transition: opacity 1s ease-out, transform 1s ease-out;
  .browser-button-close,
  .browser-button-minimize,
  .browser-button-expand {
    position: absolute;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 25%;
    padding: 0;
    border: none;
    svg {
      transition: fill 0.3s ease-in-out;
    }
  }

  .browser-button-close:focus,
  .browser-button-minimize:focus,
  .browser-button-expand:focus {
    outline: none;
  }
  &:hover .browser-button-close svg {
    fill: rgba(0, 0, 0, 0.7);
  }
  &:hover .browser-button-minimize svg {
    fill: rgba(0, 0, 0, 0.7);
  }
  &:hover .browser-button-expand svg {
    fill: rgba(0, 0, 0, 0.7);
  }
}

.windowTitle {
  color: #8a8a8a;
  padding: 3px 0px;
  font-size: 1.2rem;
  font-family: 'MontSerrat', Tahoma, Arial, sans-serif;
  font-weight: 700;
  text-align: center;
}

.mobile .windowHeader {
  background: none;
}

.mobile .windowTitle {
  color: white;
}

.browser-button-close {
  background: #ff5f57;
  left: 4px;
}

.browser-button-minimize {
  background: #ffbd2e;
  top: 25%;
  left: 18px;
}

.browser-button-expand {
  background: #28ca41;
  left: 32px;
}

.preview {
  width: 100%;
  height: 31vh;
  min-height: 20rem;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px 0px 3px 3px;
  transition: opacity 1s ease-out;
}

.mobile .preview {
  background-size: contain;
}

@media (max-width: 768px) {
  .mobile .preview {
    height: 65lvh;
  }
}

@media (min-width: 600px) {
  .preview {
    min-height: 25rem;
  }
}
