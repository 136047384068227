.site-header {
  width: 100%;
  top: 0;
  right: 0;
  position: fixed;
  background: var(--black-zero);
  z-index: 5;
  transition: opacity 1s linear;
}

@media (min-width: 769px) {
  .site-header {
    padding: 20px 0px;
    display: flex;
    justify-content: space-evenly;
    transition: opacity 1s linear;
    background: var(--black-eightyfive);
  }
}
