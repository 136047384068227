#contact-content {
  display: flex;
  flex-direction: column;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type(
  $min-vw: 320px,
  $max-vw: 2000px,
  $min-font-size: 100px,
  $max-font-size: 700px
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

.watermark {
  position: absolute;
  color: rgba(0, 0, 0, 1);
  top: 45vh;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  @include fluid-type($min-font-size: 120px, $max-font-size: 700px);
  transform: translate3D(0, -50%, 0);
  // -webkit-text-stroke: 4px rgba(255, 255, 255, 0.08);
  filter: drop-shadow(2px 2px 2px rgba(255, 255, 255, 0.2));
  cursor: none;
  transition: opacity 2s ease-in-out;
}

.contactInfo {
  position: relative;
  z-index: 3;
  top: 45vh;
  width: fit-content;
  margin: auto;
  font-size: 2rem;
  font-weight: 800;
  color: var(--orange);
  line-height: 1;
  text-align: center;
  transform: translate3D(0, -50%, 0);
}

.contactInfo div {
  margin: 30px auto;
}

#contact-content a {
  color: var(--gold);
  text-decoration: none;
}

.underline-animation {
  position: relative;
}

.underline-animation:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: -webkit-linear-gradient(right, var(--orange), var(--gold));
  transition: width 0.3s ease;
  -webkit-transition: width 0.3s ease;
}

.underline-animation:hover:after {
  width: 100%;
  left: 0;
  background: -webkit-linear-gradient(right, var(--orange), var(--gold));
}

@media (min-width: 500px) {
  .contactInfo {
    font-size: 3.5rem;
  }
}

@media (min-width: 769px) {
  .contactInfo {
    font-size: 4rem;
  }

  .cursor {
    width: 20px;
    height: 20px;
    border: 10px solid var(--white);
    border-radius: 50%;
    position: absolute;
    animation: cursorAnim 1s infinite alternate;
    pointer-events: none;
    opacity: 0;
    z-index: 2;
  }

  @keyframes cursorAnim {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.7);
    }
  }

  .cursor::after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    border: 15px solid var(--orange);
    border-radius: 50%;
    opacity: 1;
    top: -15px;
    left: -15px;
    animation: cursorAnim2 1s infinite alternate;
  }

  @keyframes cursorAnim2 {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.4);
    }
  }

  .expand {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 25px;
    left: 25px;
    animation: cursorAnim3 0.5s forwards;
    border: 10px solid var(--red);
    border-radius: 50%;
  }
}

@media (min-width: 1025px) {
  .contactInfo {
    font-size: 5rem;
  }
}
